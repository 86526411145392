let checkResultsButton = document.getElementById("checkResults");
let redoQuizButton = document.getElementById("redoQuiz");
let successText = document.getElementById("result-text-success");
let progressBar = document.getElementById("progress-bar");
let progressNumber = document.getElementById("progress");
let markers = document.getElementsByClassName("marker");

if (checkResultsButton) {
  checkResultsButton.addEventListener("click", function () {
    let questions = document.querySelectorAll(".question-container");
    let numberOfCorrectAnswers = 0;
    const oneHundred = 100;
    const topOfPage = 0;
    const approvedScore = window.approvedScore;
    questions.forEach((singleQuestion) => {
      let answers = singleQuestion.querySelectorAll(".quiz-radiobutton");
      let questionExplanationText = singleQuestion.querySelector(".question-explanation");
      let questionResultText = questionExplanationText.querySelector(".question-result");
      answers.forEach((singleAnswer) => {
        let correct = singleAnswer.dataset.attribute;
        let answered = singleAnswer.checked;
        if (answered === true) {
          if (correct === "true") {
            numberOfCorrectAnswers++;
            questionResultText.innerHTML = "Rätt";
            questionResultText.classList.add("correct");
            questionExplanationText.classList.add("display");
          } else {
            questionResultText.innerHTML = "Fel";
            questionResultText.classList.add("wrong");
            questionExplanationText.classList.add("display");
          }
        }
        singleAnswer.disabled = true;
        window.scrollTo(topOfPage, topOfPage);
        // Ska kanske användas - alert-ruta vid reload av sidan
        // window.onbeforeunload = function () {
        //   return "Om du laddar om sidan så kommer ditt resultat att försvinna, är du säker på att du vill ladda om?";
        // };
      });
    });
    let pointsInPercent = Math.trunc((numberOfCorrectAnswers / questions.length) * oneHundred);
    if (pointsInPercent >= approvedScore) {
      document.getElementById("result-text-fail").classList.add("hide");
      successText.classList.add("display");
      progressBar.style.width = "100%";
      progressNumber.innerHTML = "100%";
      for (var marker = 0; marker < markers.length; marker++) {
        markers[marker].style.border = "1px solid #2C9142";
      }
      const linkToStart = "/idrottshoppet/";
      let previousButton = document.querySelector(".next-post-button");
      previousButton.href = linkToStart;
      previousButton.innerHTML = "Tillbaka till Idrottshoppet";
    } else {
      successText.classList.add("hide");
      redoQuizButton.classList.add("display");
    }
    checkResultsButton.style.display = "none";
    document.getElementById("result-score").innerHTML = pointsInPercent + "%";
    document.getElementById("result-section").classList.add("display");
  });
}

if (redoQuizButton) {
  redoQuizButton.addEventListener("click", function () {
    location.reload();
  });
}
